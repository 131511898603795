import { defineStore } from "pinia";
import apiHome from "@/api/api/apiHome.js";
import { urlIncludeDomain, downLoadAtag } from "@/utils/tools.js";
import { formatDate } from "@/utils/dateTime.js";
import message from "@/components/functionCallComponent/message/message.js";
import config from "@/config/config.js";
export const getObjectStorageFullUrl = (url) => {
  if (urlIncludeDomain(url)) {
    return url;
  }
  return config.baseObjectStorageUrl + url;
};
const useHomeDataStore = defineStore({
  id: "useHomeDataStore",
  state: () => {
    return {
      prompt: "",
      m3u8Url: "",
      stream_key: "",
      isLoading: false,
      sseGenerate: null,
      lyrics: [],
    };
  },
  actions: {
    doCreateMusic(params, callback) {
      this.isDoCreateMusic = true;
      this.isLoading = true;
      if (this.sseGenerateCrash) {
        this.m3u8Url = "";
      }
      let lastTime = new Date().getTime();
      this.dogenerateTime = lastTime;

      setTimeout(() => {
        if (this.dogenerateTime == lastTime) {
          this.isLoading = false;
        }
      }, 1000 * 60);
      return new Promise((resolve, reject) => {
        apiHome
          .createMusic({
            type: 2,
            ...params,
            ...(this.firstResData && this.sseGenerate != null
              ? {
                  feed_id: this.firstResData.feed_id,
                }
              : {}),
          })
          .then((res) => {
            this.isDoCreateMusic = false;
            if (res.code == 200) {
              if (this.m3u8Url) {
                callback();
              }
              if (
                (!this.sseGenerate ||
                  this.curSseConnectId != res.data.conn_id) &&
                res.data.conn_id
              ) {
                this.abortSse();
                this.doGenerateConnectedSSE(res.data.conn_id);
                this.firstResData = res.data;
              }
              this.conn_id = res.data.conn_id;
              this.stream_key = res.data.stream_key;
              this.feed_id = res.data.feed_id;
              resolve(res);
            } else {
              this.isLoading = false;
              if (res.code == -200) {
                message.info({
                  maskClosable: true,
                  content:
                    "No Internet Connection. Please check your connection and try again.",
                  position: "top",
                });
              } else if (res.code == 401) {
                callback("login");
              } else {
                message.info({
                  maskClosable: true,
                  content:
                    "Failed to generate audio. Aliens are causing trouble. Try again after we deal with them.",
                  position: "top",
                });
              }
              reject(res);
            }
          })
          .catch((err) => {
            console.error("err", err);
            this.isDoCreateMusic = false;
            this.isLoading = false;
            message.info({
              maskClosable: true,
              content:
                "Failed to generate audio. Aliens are causing trouble. Try again after we deal with them.",
              position: "top",
            });
            reject(err);
          });
      });
    },
    async doGenerateConnectedSSE(conn_id) {
      this.generating = true;
      this.curSseConnectId = conn_id;
      let sseGenerate = await apiHome.doSseGetGenerate({
        params: `conn_id=${conn_id}`,
        onOpen(e) {},
        onMessage: (res) => {
          if (["inf_feed"].indexOf(res.event) >= 0) {
            let data = JSON.parse(res.data ?? "{}");
            console.log("data", data);
            if (data.song?.m3u8_url && this.m3u8Url != data.song.m3u8_url) {
              this.m3u8Url = getObjectStorageFullUrl(data.song.m3u8_url);
            }
            // if (data.song?.lyrics_segments?.length && !this.lyrics.length) {
            //   const lyrics = data.song.lyrics_segments.reduce((res, itemp) => {
            //     if (itemp.user_input_tag) {
            //       res.push({
            //         type: 'lyric_seg',
            //         seg_type: itemp.seg_type,
            //         text: itemp.user_input_tag,
            //       });
            //     }
            //     return (itemp.rows ?? []).reduce((res, item) => {
            //       res.push({
            //         type: 'lyric',
            //         seg_type: itemp.seg_type,
            //         text: item.text,
            //       });
            //       return res;
            //     }, res);
            //   }, []);
            //   this.lyrics = [].concat(this.lyrics).concat(lyrics);
            // }
          } else {
            if (res.event == "done") {
              if (this.curSseConnectId == conn_id) {
                this.sseGenerate?.abort();
                this.sseGenerateCrash = true;
                this.sseGenerate = null;
                this.generating = false;
                if (this.conn_id != this.curSseConnectId && this.conn_id) {
                  this.doGenerateConnectedSSE(this.conn_id);
                } else {
                  this.isLoading = false;
                  message.info({
                    maskClosable: true,
                    content:
                      "Generation failed ! Aliens are invading our server! We will repel them ASAP(ﾟ皿ﾟ)ｒ┏┳－－－＊",
                    position: "top",
                  });
                }
              } else {
                sseGenerate?.abort();
              }
            }
          }
        },
        onError: (e) => {},
        onClose: (e) => {
          this.generating = false;
        },
      });
      this.sseGenerate = sseGenerate;
    },
    abortSse() {
      if (this.sseGenerate) {
        this.isLoading = false;
      }
      this.curSseConnectId = null;
      this.sseGenerate?.abort();
      this.sseGenerate = null;
      this.generating = false;
    },
    doPlayReport(params) {
      return new Promise((resolve, reject) => {
        apiHome
          .doPlayReport({
            ...params,
            feed_id: this.feed_id,
            last_stream_key: this.stream_key,
          })
          .then((res) => {
            if (res.code == 200) {
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    doDownloadReport({ url }) {
      return new Promise((resolve, reject) => {
        if (!url) {
          message.info({
            maskClosable: true,
            content: "The current non-generated song cannot be downloaded",
            position: "top",
          });
          reject();
          return;
        }
        apiHome
          .doDownloadReport({
            save_node_url: url,
            m3u8_url: this.m3u8Url,
            feed_id: this.feed_id,
          })
          .then((res) => {
            if (res.code == 200) {
              downLoadAtag({
                href: getObjectStorageFullUrl(res.data.mp3_url),
                fileName: formatDate(new Date(), "YYYT-MM-DD_hh-mm") + ".mp3",
              })
                .then(() => {
                  message.info({
                    maskClosable: true,
                    content: "download success",
                  });
                })
                .catch(() => {
                  message.info({
                    maskClosable: true,
                    content: "download failed",
                  });
                });
            } else {
              message.info({
                maskClosable: true,
                content: "download failed",
                position: "top",
              });
            }
          })
          .catch((err) => {
            message.info({
              maskClosable: true,
              content: "download failed",
              position: "top",
            });
          });
      });
    },
  },
});

export default useHomeDataStore;

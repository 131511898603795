import { defineStore } from "pinia"
import { updateHeaderConfig } from "@/api/net/config.js"
import tools from "@/utils/tools"
import i18n, { $$t, $$language } from "@/i18n/i18n.js"
import GlobalConfig from "@/config/config.js"
import apiUser from "@/api/api/apiUser.js"
import router, { isNoAuthenticatedToLogin } from "../router"
import useHomeDataStore from "@/store/homeDataStore.js"

const getLocalData = () => {
  const { email } = tools.getUrlAllParams(decodeURIComponent(location.href))
  const localData = localStorage.getItem(GlobalConfig.localStorageGlobalKey) || "{}"
  const { userInfo, authData, config, isLogin, k_sso_token, appLanguage, ..._data } = JSON.parse(localData)
  if (!isLogin) {
    return { appLanguage }
  }
  if (!email || (email === userInfo.email && localData?.authData)) {
    return {
      k_sso_token,
      appLanguage,
      userInfo,
      isLogin,
      authData,
      config,
      ..._data,
    }
  }
  return {}
}

const useUserDataStore = defineStore({
  id: "userDataStore",
  state: () => {
    const data = {
      skyworkInfo: {
        isApp: true,
        isCheckLogin: false,
      },
      appInfo: {
        name: "SkyMusic.Ai",
      },
      isLogin: false, // 是否登陆
      authData: {
        // user_id: 100, // 临时使用
        // oauth_key: "788vaWdtOua0kIuClIkNWMNlcTXSaLNW"
        // app_user_id: "u-6a41a76fa9416d9106b7d26498ae03"
      },
      userInfo: {
        // 全局用户信息
      },
      config: {
        // 全局配置
      },
      appLanguage: "",
      k_sso_token: "",
      ...getLocalData(),
    }
    data.authData && updateHeaderConfig(data.authData)
    return data
  },
  actions: {
    init() {
      return new Promise((resolve, reject) => {
        this.getUserInfo()
          .then((res) => {
            this.getConfig()
              .then((res) => {
                resolve()
              })
              .catch((err) => {
                reject(err)
              })
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    clearAll() {
      localStorage.removeItem(GlobalConfig.localStorageGlobalKey)
      this.isLogin = false
      this.authData = {}
      this.userInfo = {}
      this.config = {}
      this.clearOther()
    },
    clearOther() {
      updateHeaderConfig(this.authData)
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        if (isNoAuthenticatedToLogin()) {
          resolve()
        }
        if (this?.authData?.user_id) {
          console.log("this.authData", this.authData)
          apiUser.userGetInfo(this.authData.user_id).then((res) => {
            const { data, code, msg } = res
            if (code == "200") {
              this.userInfo = data ?? {}
              resolve(data)
            } else {
              isNoAuthenticatedToLogin()
              reject()
            }
          })
        }
      })
    },
    getConfig() {
      return new Promise((resolve, reject) => {
        apiUser
          .userConfig(this.authData.user_id)
          .then((res) => {
            const { data, code, msg } = res
            if (code == "200") {
              this.config = data ?? {}
              this.appLanguage = data.app_language ?? "en"
              resolve()
            } else {
              reject()
            }
          })
          .catch((err) => {
            reject()
          })
      })
    },
    getAll() {
      const { isLogin, authData, userInfo, config, appLanguage, k_sso_token } = this
      return {
        isLogin,
        authData,
        userInfo,
        config,
        appLanguage,
        k_sso_token,
      }
    },
    setData(data) {
      Object.assign(this, data)
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
    },
    updateLoginStatus(isLogin) {
      this.isLogin = isLogin
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
      if (!isLogin) {
        const homeDataStore = useHomeDataStore()
        homeDataStore.abortSse()
      }
    },
    updateUserInfo(userInfo) {
      this.userInfo = userInfo
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
    },
    upDataAuthData(authData) {
      this.authData = authData
      updateHeaderConfig(authData)
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
      // this.init()
    },
    point() {
      apiUser.userPoint().then(() => {})
    },
  },
  getters: {
    getAuthData() {
      return this.authData
    },
    getAppInfo() {
      return this.appInfo
    },
  },
})
export default useUserDataStore

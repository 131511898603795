// 相关 response data状态码  https://dev.skymusic.ai/defs/errors.go
import axios from "axios";
import { getPublicHeader, paramFormat, interfaceSpecification } from "./config";
import Router, { isNoAuthenticatedToLogin } from "@/router/index";
import useUserDataStore from "@/store/userDataStore";
import { $$t } from "@/i18n/i18n.js";
import message from "@/components/functionCallComponent/message.jsx";
// import stringify from 'qs-stringify';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  async (config) => {
    var publicHeader = await getPublicHeader();
    config.headers = {
      ...config.headers,
      ...publicHeader,
      // 'X-Timestamp': new Date().getTime(),
    };
    return config;
  },
  (error) => {
    console.log("请求超时");
    return Promise.reject(error);
  }
);

let flag = true;
let timeoutId;

const goLogin = () => {
  useUserDataStore().clearAll();
  if (!flag) return;
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  flag = false;

  console.log("您的账号已登出，请确认登出由您本人操作");
  // message.error({
  //   content: "您的账号已登出，请确认登出由您本人操作，注意账户安全!",
  // });

  timeoutId = setTimeout(() => {
    flag = true;
  }, 2000);
};

axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const res = response?.data ?? {};
    if (res.code == 0) {
      res.code = 200;
    }
    return res;
  },
  (err) => {
    // 对响应错误做点什么
    console.error("接口错误=>", err);
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 401: // 用户身份过期, 清空用户信息, 变更为未登录状态
          goLogin();
          isNoAuthenticatedToLogin();
          return {
            code: 401,
            msg: "用户身份过期, 请重新登录",
            data: err.response.data?.data,
          };

        default:
          break;
      }
    }
    if (err.code == "ERR_NETWORK") {
      let config = err.config;
      // config.retry = 3;
      config.__retryCount = config.__retryCount || 0;
      config.retryDelay = config.retryDelay ?? 5000;
      if (!config?.retry || config.__retryCount >= config.retry) {
        return {
          code: -200,
          msg: "无法连接，请检查你的网络状态",
        };
      }
      config.__retryCount += 1;
      config.__backoff = new Promise(function (resolve) {
        setTimeout(function () {
          resolve();
        }, config.retryDelay);
      });
      return config.__backoff.then(function () {
        return axios(config);
      });
    } else {
      return err.response.data;
    }
  }
);

const axiosDef = (
  url,
  data = {},
  {
    method = "get",
    headers = {},
    timeout = 300000,
    cancelToken = null,
    ...others
  } = {}
) => {
  data = paramFormat(data ?? {});
  const params = method === "get" ? { params: data } : { data: data };
  return axios({
    method,
    url,
    cancelToken,
    headers,
    timeout,
    ...others,
    ...params,
  });
};

// axiosInterfaceSpecification
export default (...arg) => {
  return new Promise(async (resolve, reject) => {
    axiosDef(...arg)
      .then((res) => {
        resolve(interfaceSpecification(res));
      })
      .catch((err) => {
        reject(err);
      });
  });
};

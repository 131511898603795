import globalConfig from '@/config/config.js';
import { $$language } from "@/i18n/i18n.js"
import { getType, getDeviceId } from "@/utils/tools.js"

const headerConfig = {
  'X-App-Name': globalConfig.appname,
};

let AuthData ={
  auth_secret : "8I7RZPf0SOIoCKIT9e8WrteSbFDBFzXI",
  auth_key: "mH8HP1kMFBhT8c3gFgFWrJolHsuAY4nX",
  user_id: 1990
}

const getAuthorization = ()=>{
  const { oauth_key, auth_secret, timestamp } = AuthData;
  return `oauth_token=${oauth_key},oauth_signature=${auth_secret},oauth_timestamp=${timestamp}`;
}
const getUserAgent = async ()=>{
  //  return 'en/1.0.1/h5//web/635e3f65711348759f54180b91220156/unknown//3x';
   const osVersion = '';
   const deviceId = await getDeviceId();
   const netType = 'unknown';
   const deviceType = '';
   const resolution = '3x';
   // $$language()
   return ["cn", globalConfig.version, "h5", osVersion, "web", deviceId ,netType, deviceType, resolution].join("/")
}
export const paramFormat =(data = {}) => {
  const result = {}
  const time = new Date().getTime()
  /* 0 app内部进入 1 外部浏览进入 */
  Object.assign(result, {
    time,
    ...data,
  })
  return result
}

export const updateHeaderConfig = (authData) => { 
  console.log('updateHeaderConfig',authData);
  AuthData = authData;
};

export const getPublicHeader = async ()=>{
  return {
    ...headerConfig,
    // 'Authorization': getAuthorization(),
    'X-User-Agent': await getUserAgent(),
    'X-Auth-Timestamp': Math.floor(new Date().getTime())
  }
}

// 统一返回格式
// res = {
//   code: '',
//   data: {},
//   "msg": "xxxxx"
// };

export const interfaceSpecification = (data) => { // 接口规范
  if (getType(data) === 'Object') {
    return data;
  } else {
    return data;
    console.error('非标准接口数据格式', data);
  }
};
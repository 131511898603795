export const baseUrl = import.meta.env.VITE_API_URL;
const ApiConfig = {
  loginIn: "/api/user/login",
  loginOut: "/api/user/logout",
  createMusic: "/api/inf/feed/generate",
  /// sse 获取生成音乐状态
  sseGetGenerate: "/api/sse/subscribe",
  playReport: "/api/inf/feed/play/report",
  sendPoint: "/api/inf/report",

  getCosCred: "/api/cos-cred",
  getCosPathKey: "/api/upload-path",
  getOssCred: "/api/misc/cos-token",
  getOssPathKey: "/api/oss-upload-path",

  /// 保存参考伴奏并反解析prompt,不保存
  saveReferenceMusicTmp: "/api/ugc/parse/music_link",
  /// 删除参考伴奏
  deleteReferenceMusic: "/api/ugc/accompany/refer/delete",
};

export default ApiConfig;

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import setupDirective from './lib/directive/directive';
import VueVideoPlayer from '@videojs-player/vue'
import i18n from './i18n/i18n.js';
import VConsole from 'vconsole';
import VueLazyload from 'vue-lazyload'
import { isDev } from '@/utils/tools.js';
import vue3GoogleLogin from 'vue3-google-login';

import '@/assets/css/css.js'
const pinia = createPinia();
const app = createApp(App)

app.use(pinia)

setupDirective(app)
app.use(router)
app.use(store)
app.use(VueVideoPlayer)
app.use(i18n)
app.use(vue3GoogleLogin, {
   clientId: '1009518199533-vs16mm9a56v8i194te54c8qkjp9o5g0q.apps.googleusercontent.com',
   scope: 'profile email'
 });
app.use(VueLazyload)

import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
app.use(vue3videoPlay)
app.mount('#app')
if(isDev()){
   window.__vConsole = new VConsole({ theme: 'dark' });
}

<template>
  <router-view></router-view>
</template>
<script setup>
import { watch, reactive, onMounted, ref } from "vue";
import i18n from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore";
import GlobalConfig from "@/config/config.js";
import { getCookie } from "@/utils/cookie.js";
import apiUser from "@/api/api/apiUser.js";
import router from "@/router/index.js";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import firebaseInit from "@/utils/analytic/firebaseUtils.js";
import useHomeDataStore from "@/store/homeDataStore.js";

let firebase;

const showRouter = ref(false);

const userDataStore = useUserDataStore();
let k_sso_token = getCookie("k_sso_token") ?? "";
const registCacheData = (_this) => {
  document.addEventListener("visibilitychange", function () {
    //浏览器切换事件
    if (document.visibilityState == "hidden") {
      GlobalAudioPlayer.pause(GlobalAudioPlayer.currentUrl);
    }
  });
  window.addEventListener("beforeunload", function () {
    const homeDataStore = useHomeDataStore();
    homeDataStore.abortSse();
  });
};

watch(
  () => userDataStore.appLanguage,
  (newVal) => {
    if (userDataStore.appLanguage == "cn") {
      i18n.global.locale = "cn";
    } else {
      i18n.global.locale = "en";
    }
    return;
  },
  {
    immediate: true,
    deep: true,
  }
);

function isCurrentRouteSkywork() {
  return (
    window.location.pathname === "/skywork" || window.location.pathname === "/"
  );
}

onMounted(async () => {
  registCacheData();
  // try {
  //   firebase = await firebaseInit()
  // } catch (e) {
  //   console.error(e)
  // }
});
</script>
<style lang="scss">
.lottie-animation-contaniner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .lottie-animation-content {
    height: 80px;
    width: 80px;
  }
}
</style>

// 'success' | 'warning' | 'info' | 'error'
import CustomMessage from './message/message.js';
const _duration = 3000;

const mapCache = {
  
}

export const info = (option={}, tag) => {
  const {content, duration, ...others} = option;
  if(tag){
    if(mapCache[tag]){
      return;
    }else{
      mapCache[tag] = true;
    }
  }
  return CustomMessage({
    maskClosable: true,
    duration: duration || _duration,
    content,
    onClose:()=>{
      option.onClose && option.onClose();
      delete mapCache[tag]
    },
    ...others,
    type: "info",
  });
};
export const success = (option={}, tag) => {
  const {content, duration, ...others} = option;
  if(tag){
    if(mapCache[tag]){
      return;
    }else{
      mapCache[tag] = true;
    }
  }
  return CustomMessage({
    maskClosable: true,
    duration: duration || _duration,
    content,
    onClose:()=>{
      option.onClose && option.onClose();
      delete mapCache[tag]
    },
    ...others,
    type: "success",
  });
};
export const warning = (option={}, tag) => {
  const {content, duration, ...others} = option;
  if(tag){
    if(mapCache[tag]){
      return;
    }else{
      mapCache[tag] = true;
    }
  }
  return CustomMessage({
    maskClosable: true,
    duration: duration || _duration,
    content,
    onClose:()=>{
      option.onClose && option.onClose();
      delete mapCache[tag]
    },
    ...others,
    type: "warning",
  });
};
export const error = (option={}, tag) => {
  const {content, duration, ...others} = option;
  if(tag){
    if(mapCache[tag]){
      return;
    }else{
      mapCache[tag] = true;
    }
  }
  return CustomMessage({
    maskClosable: true,
    duration: duration || _duration,
    content,
    onClose:()=>{
      option.onClose && option.onClose();
      delete mapCache[tag]
    },
    ...others,
    type: "error",
  });
};
export const loading = (option={}, tag) => {
  const {content, duration, ...others} = option;
  if(tag){
    if(mapCache[tag]){
      return;
    }else{
      mapCache[tag] = true;
    }
  }
  return CustomMessage({
    duration: duration || _duration,
    content,
    onClose:()=>{
      option.onClose && option.onClose();
      delete mapCache[tag]
    },
    ...others,
    type: "loading",
  });
};

export const clear =() =>{
  CustomMessage.clear();
}



export default {
  success,
  warning,
  info,
  error,
  loading,
  clear
}


// preventEvent.js

import { getUuid, getType } from '@/utils/tools';

// v-prevent-event:eventName="{
//   callback: (e)=>{},
//   cancel: false,  cancel 优先级高于 diabledtime
//   diabledtime: 1000,
//   disableOpacity:0.5,
    
//   
//   renderState: false 渲染释放。回归到之前状态 true 渲染禁止，独立于其他参数，可用此参数单独控制渲染事件禁止效果，默认请置false，用完记得释放
// }"

// renderState  和其他只能二选一

const preventEvent = {
  created(el, binding, vnode, prevVnode) {
  },
  // 在元素被插入到 DOM 前调用
  beforeMount(el, binding, vnode, prevVnode) {},
  // 在绑定元素的父组件
  // 及他自己的所有子节点都挂载完成后调用
  mounted(el, binding, vnode, prevVnode) {
    const { arg, value } = binding;
    let eventName = arg;
    const handleEvent = (event) => {
      if (el.__preventEventDisable__) {
        event.preventDefault();
        event.stopImmediatePropagation();
      } else {
        if(getType(value)=='Object'){
          let callback = value.callback;
          let time= value.diabledtime
          let cancel = value.cancel;
          let disableOpacity = !isNaN(Number(value.disableOpacity)) ? value.disableOpacity : 0.5;

          if(getType(callback)=='Function'){
            callback(event);
          }
          if(!cancel){
            el.__preventEventDisable_cp__= true;
            el.__preventEventDisable__= true;
            el.classList.add("__disable");
            el.style['pointer-events']='none';
            el.style['cursor']='not-allowed';
            el.style['opacity']=`${disableOpacity}`;
            if(!isNaN(Number(time))){
              el.__preventEventTimer__ = setTimeout(() => {
                if(el.__preventEventrenderState__ != true){
                  el.__preventEventTimer__ = null;
                  el.__preventEventDisable_cp__= false;
                  el.__preventEventDisable__ = false;
                  el.style['pointer-events']='auto';
                  el.style['cursor']='pointer'
                  el.style['opacity']='1';
                  el.classList.remove("__disable");
                }
              }, time);
            }
          }
        }
      }
    };
    if(getType(value)=='Object'){
      el.__preventEventeEventName__= eventName;
      el.addEventListener(eventName, handleEvent);
    }
    // 在组件销毁前移除事件监听器
    el.__preventEventHandler__ = handleEvent;
  },
  // 绑定元素的父组件更新前调用
  beforeUpdate(el, binding, vnode, prevVnode) {
    
  },
  // 在绑定元素的父组件
  // 及他自己的所有子节点都更新后调用
  updated(el, binding, vnode, prevVnode) {
    const { arg, value } = binding;
    el.__preventEventDisable_cp__ == el.__preventEventDisable_cp__ ?? false;
    if(getType(value)=='Object'){
      let eventName = arg;
      el.__preventEventrenderState__= value.renderState;
      // 渲染层面的禁止事件 用户控制
      if(el.__preventEventrenderState__ == false || el.__preventEventrenderState__ == true){
        if(el.__preventEventrenderState__ == false && el.__preventEventDisable_cp__ == false){
          if(el.__preventEventTimer__){
            clearTimeout(el.__preventEventTimer__);
          }
          el.__preventEventDisable__= false;
          el.style['pointer-events']='auto';
          el.style['cursor']='pointer';
          el.style['opacity']='1';
          el.classList.remove("__disable");
        } else {
          let disableOpacity = !isNaN(Number(value.disableOpacity)) ? value.disableOpacity : 0.5;
          el.__preventEventDisable__= true;
          el.classList.add("__disable");
          el.style['pointer-events']='none';
          el.style['cursor']='not-allowed';
          el.style['opacity']=`${disableOpacity}`;
        }
      }
      if(value.cancel && el.__preventEventrenderState__ != true){
        if(el.__preventEventTimer__){
          clearTimeout(el.__preventEventTimer__);
        }
        el.__preventEventDisable_cp__= false;
        el.__preventEventDisable__= false;
        el.style['pointer-events']='auto';
        el.style['cursor']='pointer';
        el.style['opacity']='1';
        el.classList.remove("__disable");
      } else if(value.cancel && el.__preventEventrenderState__ == true){
        el.__preventEventDisable_cp__= false;
      }
    }
  },
  // 绑定元素的父组件卸载前调用
  beforeUnmount(el, binding, vnode, prevVnode) {
    // 移除事件监听器
    const { arg, value } = binding;
    let eventName = arg;
    if(el.__preventEventTimer__){
      clearTimeout(el.__preventEventTimer__);
    }
    el.removeEventListener(eventName,  el.__preventEventHandler__);
  },
  // 绑定元素的父组件卸载后调用
  unmounted(el, binding, vnode, prevVnode) {
  }
};

export default preventEvent;

<template>
  <div class="loader" :style="{
    '--loaderSize': props.size+'px',
    '--loaderColor': props.color,
    '--loaderStrokeWidth': props.strokeWidth
  }">
    <div class="loader-main-content height-all flex-center">
      <slot></slot>
    </div>
    <div class="loader-main height-all flex-center" v-show="renderData.loading">
      <div class="loader-circle">
          <svg :viewBox="`${props.size/2} ${props.size/2} ${props.size} ${props.size}`"><circle :cx="props.size" :cy="props.size" :r="props.size/2-props.strokeWidth/2 -props.marginSize" fill="none"></circle></svg>
      </div>
    </div>
   </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  onMounted,
} from 'vue';

  import { useRouter, useRoute } from 'vue-router'

  const Router = useRouter()
  const route = useRoute()

  const props = defineProps({
    loading:{
      type:Boolean,
      default:false
    },
    size:{
      type:Number,
      default:50
    },
    color:{
      type:String,
      default:'#0057FF'
    },
    marginSize:{
      type:Number,
      default:0
    },
    strokeWidth:{
      type:Number,
      default:3
    }
  })
  const emits = defineEmits([])
  const renderData = reactive({
    loading: props.loading ?? false,
  })

  watch(() => props.loading, (newVal, oldVal) => {
    renderData.loading = props.loading;
    
  })

  onMounted(() => {
    renderData.loading = props.loading ?? false;
  })
</script>
<style lang='scss'>
  @keyframes spinsvg{
    0% {
      stroke-dasharray: 1,200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90,150;
      stroke-dashoffset: -40;
    }
    100% {
      stroke-dasharray: 90,150;
      stroke-dashoffset: -120;
    }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
 .loader {
    position: relative;
    &{
      height: var(--loaderSize);
      width: var(--loaderSize);
    }
    .loader-main{
      height: var(--loaderSize);
      width: var(--loaderSize);
      position: absolute;
      left: 0;
      top: 0;
    }
    .loader-circle{
      width: var(--loaderSize);
      height: var(--loaderSize);
      border-radius: 50%;
      color: var(--loaderColor);
      transform-origin: center center;
      animation: spin 1.8s linear infinite; /* 设置动画 */
    }
    circle {
      transform-origin: center center;
      stroke: currentColor;
      stroke-width: var(--loaderStrokeWidth);
      stroke-linecap: round;
      border-radius: 50%;
      animation: spinsvg 1.5s linear infinite; /* 设置动画，与主体动画不同的持续时间 */
    }
  }
</style>
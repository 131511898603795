import axiosIS from '@/api/net/axios.js';
import ApiConfig,{ baseUrl} from '@/config/apiConfig.js';
import sseRequest from '@/api/net/sse.js';
// 举报接口
export function createMusic(data = {}, url) {
  return axiosIS(
    url??`${baseUrl}${ApiConfig.createMusic}`,
    data,
    { method: 'post' }
  );
}

export const doSseGetGenerate =async ({ params, onOpen, onMessage, onError, onClose }) => {
  return await sseRequest({
    method: "GET",
    url: `${baseUrl}${ApiConfig.sseGetGenerate}?${params}`,
    params: null,
    onOpen,
    onMessage,
    onError,
    onClose,
  })
}

export const saveReferenceMusic = (data = {}, options = {}) => {
  return axiosIS(`${baseUrl}${ApiConfig.saveReferenceMusic}`, data, {
    method: "post",
    ...options,
  });
}
export const deleteReferenceMusic = (data = {}, options = {}) => {
  return axiosIS(`${baseUrl}${ApiConfig.deleteReferenceMusic}`, data, {
    method: "post",
    ...options,
  });
}



export function doPlayReport(data = {}, url) {
  return axiosIS(
    url??`${baseUrl}${ApiConfig.playReport}`,
    data,
    { method: 'post' }
  );
}

export function doDownloadReport(data = {}, url) {
  return axiosIS(
    url??`${baseUrl}/api/inf/song/save`,
    data,
    { method: 'post' }
  );
}



export default {
  createMusic,
  doSseGetGenerate,
  doPlayReport,
  doDownloadReport
}
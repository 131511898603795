<template>
  <div
    class="custom-message"
    @click="onMaskClick"
    v-if="renderData.ifRender"
    ref="refCustomMessage"
    :class="`render-position-${props.position} render-type-${props.type}  render-closable-${props.closable} ${props.customClass}`"
  >
    <div class="custom-message-content flex" @click.stop>
      <div
        class="custom-message-content-icon"
        @click="onClickClose"
        v-if="props.type === 'loading' || props.closable"
      >
        <div class="custom-message-content-close" v-if="props.closable">
          <i class="block"></i>
        </div>
        <LoaderCircle
          v-if="props.type === 'loading'"
          :loading="true"
          :size="20"
          :strokeWidth="1.2"
          color="#fff"
        />
      </div>
      <div class="custom-message-info-ico-box" v-show="props.infoIco && props.type == 'info'">
        <div class="custom-message-info-ico">
          <i class="block"></i>
        </div>
      </div>
      <div class="custom-message-text tl">{{ props.content }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, reactive, watch } from "vue";
import LoaderCircle from "@/components/animation/LoaderCircle.vue";
const emits = defineEmits(["update:visible"]);
const props = defineProps({
  customClass: {
    type: String,
    default: "",
  },
  position: {
    type: String,
    default: "center", // center, top, bottom
  },
  type: {
    type: String,
    default: "info", // success warning info error loading
  },
  visible: {
    type: Boolean,
    default: true,
  },
  content: {
    type: String,
    default: "",
  },
  duration: {
    type: Number,
    default: 3000,
  },
  closable: {
    type: Boolean,
    default: false,
  },
  forbidClick: {
    type: Boolean,
    default: false,
  },
  maskClosable: {
    type: Boolean,
    default: false,
  },
  Click: {
    type: Function,
    default: () => {},
  },
  onClose: {
    type: Function,
    default: () => {},
  },
  infoIco: {
    type: Boolean,
    default: false,
  },
  beforeClose: {
    type: Function,
    default: null,
  },
});
const refCustomMessage = ref(null);
const renderData = reactive({
  visible: false,
  ifRender: true,
});

const stateData = {
  closeTime: null,
};

const afterShow = () => {
  if (props.duration > 0 && props.type !== "loading") {
    stateData.closeTime = setTimeout(() => {
      close();
    }, props.duration);
  }
};

// 关闭
const close = async () => {
  props.beforeClose && (await props.beforeClose(close));
  renderData.visible = false;
  if (stateData.closeTime != null) {
    clearTimeout(stateData.closeTime);
  }
  stateData.closeTime = null;
};

const onClickClose = () => {
  if (props.closable) {
    close();
  }
};

const onMaskClick = () => {
  if (props.maskClosable) {
    close();
  }
};

watch(
  () => props.visible,
  (newValue, oldValue) => {
    renderData.visible = props.visible;
    if (newValue) {
      afterShow();
    }
  }
);

watch(
  () => renderData.visible,
  (newValue, oldValue) => {
    if (!newValue) {
      props.onClose && props.onClose();
      emits("update:visible", newValue);
    }
  }
);

const reRender = () => {
  renderData.ifRender = false;
  nextTick(() => {
    renderData.ifRender = true;
  });
};

const initResizeObserve = () => {
  if (refCustomMessage.value) {
    const resizeObserver = new ResizeObserver(() => {
      reRender();
    });
    resizeObserver.observe(refCustomMessage.value);
  }
};

onMounted(() => {
  renderData.visible = props.visible;
  // initResizeObserve();
  afterShow();
});
</script>

<style>
.custom-message {
  position: fixed;
  z-index: 999;
  background-color: #333;
  background: rgba(0, 0, 0, 0.1);
  transform: translateZ(0);
  overflow: hidden;

  backdrop-filter: blur(10px);

  .custom-message-content {
    padding: 20px 20px;
    background: rgba(30, 30, 30, 0.75);
    border-radius: 12px;
    color: #fff;
    max-width: 320px;
    width: max-content;
    text-align: left;
  }
  .custom-message-content-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-message-info-ico-box {
    margin: 4px 12px 0 0;
    .custom-message-info-ico {
      background: url(../../../assets/img/home/info-3x.png);
      background-size: 100% 100%;
      height: 14px;
      width: 14px;
    }
  }
  .custom-message-text {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 24px;
  }

  .custom-message-content-close {
    background-color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../../assets/img/ic_close_circle_fill.png);
    background-size: 100% 100%;
  }
  &.render-type-loading {
    .custom-message-content {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .custom-message-content-icon {
        height: 30px;
        width: 30px;
        margin: 0 0 8px 0;
      }
      .custom-message-text {
      }
    }
  }

  &.render-closable-true {
    .custom-message-content {
      flex-direction: column;
      .custom-message-content-icon {
        margin: 0 auto 8px auto;
        height: 30px;
        width: 30px;
      }
    }
  }

  &.render-position-top {
    position: fixed;
    top: 0;
    left: unset;
    right: unset;
    bottom: unset;
    left: 50%;
    transform: translateX(-50%) translateY(var(--customTransY));
    background: rgba(0, 0, 0, 0);
    .custom-message-content {
    }
  }

  &.render-position-bottom {
    position: fixed;
    top: unset;
    left: unset;
    right: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(var(--customTransY));
    background: rgba(0, 0, 0, 0);
    .custom-message-content {
    }
  }
  &.render-position-center {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    .custom-message-content {
    }
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router"
import { isDev } from "@/utils/tools.js"
import useUserDataStore from "@/store/userDataStore"
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js"
import message from "@/components/functionCallComponent/message.jsx"
export const loginRoutes = []
export const noAuthenticationRoutes = []

export const isNoAuthenticatedToLogin = () => {
  const userDataStore = useUserDataStore()
  const regs = [...noAuthenticationRoutes, ...loginRoutes].map((item) => item?.split("/").pop().toLocaleLowerCase()).join("|")
  if (
    (window.location.pathname == "/" || !new RegExp(`(${regs})$`).test(window.location.pathname.split("/").pop().toLocaleLowerCase())) &&
    !userDataStore.isLogin
  ) {
    return true
  }
  return false
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      // 首页
      path: `/`,
      name: "home",
      component: () => import("@/page/home/home.vue"),
    },
    {
      // 分享
      path: `/share`,
      name: "share",
      component: () => import("@/page/share/share.vue"),
    },
    {
      // 分享
      path: `/land`,
      name: "land",
      component: () => import("@/page/land/land.vue"),
    },
    ...(isDev()
    ? [
        {
          // 测试组件页面
          path: `/test`,
          name: "test",
          component: () => import("@/page/test/test.vue"),
        },
        {
          // 测试组件页面
          path: `/test1`,
          name: "test1",
          component: () => import("@/page/test/test1.vue"),
        },
        {
          // 测试组件页面
          path: `/watingtest`,
          name: "watingtest",
          component: () => import("@/page/home/waiting.vue"),
        },
      ]
    : []),
  ],
})

// 在应用的状态中跟踪导航历史
export let navigationHistory = []

// 监听路由变化
router.beforeEach((to, from, next) => {
  message.clear()
  GlobalAudioPlayer.pause(GlobalAudioPlayer.currentUrl)
  to.meta.from = from.fullPath
  // 更新导航历史
  next()
})

// 在全局导航守卫中检查是否是最后一个页面
router.afterEach((to, from) => {
  // 检查是否是最后一个页面
  console.log("navigationHistory2", navigationHistory)
  if (to.path !== "/" && to.path !== "/skywork") {
    if (navigationHistory.length === 0 || to.path !== navigationHistory[navigationHistory.length - 1]) {
      navigationHistory.push(to.path)
    }
  }
})

export default router

// 获取指定名称的cookie
export const getCookie = (name) => {
  var strcookie = document.cookie //获取cookie字符串
  var arrcookie = strcookie.split("; ") //分割
  //遍历匹配
  for (var i = 0; i < arrcookie.length; i++) {
    var arr = arrcookie[i].split("=")
    if (arr[0] == name) {
      return arr[1]
    }
  }
  return ""
}

// 打印所有cookie
export const print = () => {
  var strcookie = document.cookie //获取cookie字符串
  var arrcookie = strcookie.split(";") //分割

  //遍历匹配
  for (var i = 0; i < arrcookie.length; i++) {
    var arr = arrcookie[i].split("=")
    console.log(arr[0] + "：" + arr[1])
  }
}

export const getDevice = () => {
  const ua = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod|ios/i.test(ua)) {
    return "ios"
  } else if (/android/.test(ua)) {
    return "android"
  }
  return "other"
}

export const isApp = () =>
  /^(thevoice|starmaker|sargam|suaraku|sm|tvp|sm_in|sm_id|stm|jal|mld|sme)/i.test(window.navigator.userAgent) || window.stBridge != undefined
export const isIos = () => getDevice() === "ios"
export const isAndroid = () => /android/i.test(window.navigator.userAgent)
export const isWeixin = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  return /micromessenger/.test(ua) || /wechat/.test(ua)
}
export const isQQ = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  return /qqbrowser/.test(ua) || /qzone/.test(ua) || /qq/.test(ua)
}

export const isWeibo = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  return /weibo/.test(ua)
}

export const isWechatMoments = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  return /pengyouquan/.test(ua)
}

export const extractVersion = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  // const versionPattern = /android\/(\d+\.\d+\.\d+)/;
  // const match = ua.match(versionPattern);

  // // 如果找到匹配项，返回版本号，否则返回null
  // return match ? match[1] : null;
  
  // const regex = /^(?:[^\/]*\/){10}([^\/]+)/

  // // Extract the content between the fourth and fifth slashes
  // const match = ua.match(regex)

  // if (match && match[1]) {
  //   const content = match[1]
  //   return content  // Output: 1.7.0
  // } else {
  //   return null
  // }

  return getFifthFromLastSlash(ua)
}

function getFifthFromLastSlash(str) {
  // 检查字符串是否包含至少五个'/'，否则无法获取倒数第五个
  if (str.split('/').length < 6) {
    return null; // 或者抛出一个错误，取决于你的需求
  }

  // 将字符串按'/'分割成数组
  const parts = str.split('/');

  // 确保数组有足够的元素
  if (parts.length < 6) {
    return null; // 同样，这里根据需要处理
  }

  // 获取倒数第五个元素
  const fifthFromLast = parts[parts.length - 6];

  return fifthFromLast;
}

// 判断版本号 返回 > 1 < -1 相等 0
export const compareVersion = (v1, v2) =>{
  if (v1 == v2) return 0
  v1 = v1.split('.')
  v2 = v2.split('.')
  var len = Math.max(v1.length, v2.length)
  // 调整两个版本号位数相同
  while (v1.length < len) {
    v1.push('0')
  }
  while (v2.length < len) {
    v2.push('0')
  }

  // 循环判断每位数的大小
  for (let i = 0; i < len; i++) {
    var num1 = parseInt(v1[i])
    var num2 = parseInt(v2[i])

    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  }

  return 0
}

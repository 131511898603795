import { isDev } from "@/utils/tools.js";
export default {
  appname: "SkyMusic.AI",
  curObjectStorageType: "OSS", // OSS || COS
  version: "1.0.1",
  localStorageGlobalKey: "skywork_music_ai",
  curObjectStorageExpirTimeForwardTo: 30 * 1000, //mm
  baseObjectStorageUrl: import.meta.env.VITE_COS_BASE_URL,
  baseAssetsUrl: import.meta.env.VITE_PUBLIC_PATH,
};

export const linkMap = {
  waitingList: 'https://tally.so/r/3ylWOg',
  youtube: "https://youtube.com/@melodioai?si=_Q-_vIY6Z7hhaYiJ",
  twitter: "https://x.com/Melodioai",
  Discord: "https://discord.gg/aSGcjSYB8A",
  termsofservice:
    "https://plausible-nape-c6d.notion.site/Terms-of-Service-794a545bc9f24996a90bca2801aab8df?pvs=4",
  privacypolicy:
    "https://plausible-nape-c6d.notion.site/Privacy-Policy-eb0e6079c3c34b0c9e5b3eaceb4ae8d8?pvs=4",
};


export const openLink = (link, _isBlank)=>{
  window.open(link, _isBlank);
}

export const goStore = () => {
  if (isIOS()) {
    console.log("当前设备是ios")
    window.open("https://apps.apple.com/app/melodio-vibe-your-moment/id6504763479", "_blank")
  } else {
    console.log("当前设备是安卓")
    window.open("https://play.google.com/store/apps/details?id=ai.melodio.android ", "_blank")
  }
}

const isIOS = () => {
  const userAgent = navigator.userAgent
  console.log("userAgent", userAgent)
  return /iPhone|iPad|iPod|Mac/i.test(userAgent)
}


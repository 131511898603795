// clickOutside.js
const clickOutside = {
  beforeMount(el, binding) {
    // 点击外部区域触发的回调函数
    const handleClickOutside = (event) => {
      if (!el.contains(event.target) && el !== event.target) {
        binding.value(event);
      }
    };

    // 将回调函数绑定到 document 的点击事件
    document.addEventListener('click', handleClickOutside);

    // 在组件销毁前移除事件监听器
    el.__clickOutsideHandler__ = handleClickOutside;
  },
  beforeUnmount(el) {
    // 移除事件监听器
    document.removeEventListener('click', el.__clickOutsideHandler__);
  },
};

export default clickOutside;

import { createI18n } from 'vue-i18n';
import GlobalConfig from '@/config/config.js';
import commonEn from './en/common.json';
import commonCn from './cn/common.json';

const messages = {
  en: {
    common: commonEn,
  },
  cn: {
    common: commonCn,
  },
};
const localData = localStorage.getItem(GlobalConfig.localStorageGlobalKey) || "{}"
const { appLanguage } = JSON.parse(localData);

const i18n = createI18n({
  locale: appLanguage ?? "en", // 默认语言
  fallbackLocale: "en", // 如果找不到当前语言的翻译，默认回退到的语言
  messages,
})


export const $$t = (key) => {
  return i18n.global.t(key);
}

export const $$language = (language) => {
  if(Object.keys(messages).includes(language)){
    i18n.global.locale = language;
  }
  return i18n.global.locale;
}

export default i18n;
import axiosIS from '@/api/net/axios.js';
import ApiConfig,{ baseUrl} from '@/config/apiConfig.js';
export default {
  // SVC-web邮箱注册
  // https://rg975ojk5z.feishu.cn/docx/Zct5d1uKsosaLoxhT0qcbHownpc
  userRegister(data = {
  }) {
    return axiosIS(
      `${baseUrl}${ApiConfig.signUp}`,
      data,
      { method: 'post' }
    );
  },

  // SVC-邮箱登录(三方注册登录)
  // https://rg975ojk5z.feishu.cn/docx/Yrgrd964roO1cDxHgz8cc4HcnBh
  userLogin(data = {
  }) {
    return axiosIS(
      `${baseUrl}${ApiConfig.loginIn}`,
      data,
      { method: 'post'}
    );
  },

  // SVC-邮箱注册忘记密码，服务端校验发邮件
  // https://rg975ojk5z.feishu.cn/docx/VqWHdQZqKo3gqfxyK1MctNTJnv7
  userSendEmailToRetrievePassword(data = {
  }) {
    return axiosIS(
      `${baseUrl}${ApiConfig.forgetPassword}`,
      data,
      { method: 'post' }
    );
  },

  // SVC-忘记密码后重置密码
  // https://rg975ojk5z.feishu.cn/docx/TpFydOEQLo8uzZxz8EocGGk5n6f
  userResetPassword(data = {
  }) {
    return axiosIS(
      `${baseUrl}${ApiConfig.resetPasswordAuth}`,
      // /api/web/password 新接口后续换吧
      data,
      { method: 'put' }
    );
  },

  // SVC-邮箱用户登录后正常修改密码操作
  // https://rg975ojk5z.feishu.cn/docx/Ele2dWqgEoki2Ux0Ub9cz8H5nSh
  userModifyPassword(data = {
  }) {
    return axiosIS(
      `${baseUrl}${ApiConfig.resetPassword}`,
      data,
      { method: 'post' }
    );
  },
  // 邀请码
  userInvite(data = {
  }) {
    return axiosIS(
      `${baseUrl}/api/user/invite`,
      data,
      { method: 'post' }
    );
  },
  // SVC-登出
  //   https://rg975ojk5z.feishu.cn/docx/DhN9d9zl1ojeryx2B6rcTay3ncd
  userLoginOut(data = {}, user_id) {
    return axiosIS(
      `${baseUrl}${ApiConfig.loginOut}/${user_id}`,
      data,
      { method: 'post' }
    );
  },
  // SVC-个人用户基本信息
  // https://rg975ojk5z.feishu.cn/docx/CZ9Wd29fRoxCVHxf6d9cbkrGnhc
  userGetInfo(user_id) {
    return axiosIS(
      `${baseUrl}${ApiConfig.getUserInfo}/${user_id}`,
      {},
      { method: 'get' }
    )
  },
  // 埋点上报
  userPoint() {
    return axiosIS(
      `${baseUrl}${ApiConfig.sendPoint}`,
      {},
      { method: 'get' }
    )
  },
  // SVC-个人用户基本信息更新
  // https://rg975ojk5z.feishu.cn/docx/A6RIdAv5HofbOXxV3pxcSxKNn5f
  // updateUserInfo(data) {
  //   return axiosIS(
  //     `${baseUrl}/web/ai/user/edit/profile`,
  //     data,
  //     { method: 'post' }
  //   );
  // },
  // SVC-个人用户基本信息更新
  // https://rg975ojk5z.feishu.cn/docx/A6RIdAv5HofbOXxV3pxcSxKNn5f
  sendEmail(email) {
    return axiosIS(
      `${baseUrl}${ApiConfig.sendEmail}?email=${email}`,
      {},
      { method: 'get' }
    );
  },
  userConfig() {
    return axiosIS(
      `${baseUrl}${ApiConfig.getConfig}`,
      {},
      { method: 'get' }
    )
  },
  switchLanguage(data = {}) {
    return axiosIS(`${baseUrl}${ApiConfig.language}`, data, { method: "post" })
  },
}
